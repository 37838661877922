<template>
  <div
    class="button-outer-container flex font-medium w-full text-white m-2.5 bg-gradient-to-r from-dark-blue-600 to-dark-blue-800"
  >
    <div class="flex items-center flex-wrap button-container w-full px-7.5">
      <div>
        <a
          class="block whitespace-nowrap w-full text-white underline hover:text-black"
          :href="register"
          target="_blank"
          >{{ t("register") }}</a
        >
        <a class="block whitespace-nowrap w-full text-white underline hover:text-black" :href="login" target="_blank">{{
          t("login")
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import MixinOutlined from "~/mixins/outlined.js";

export default {
  name: "ButtonBasic",
  mixins: [MixinOutlined],
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });

    return { t, locale: locale.value };
  },
  computed: {
    classes() {
      const classes = [];
      classes.push(this.outlined_class);
      if (this.backgroundColor) {
        classes.push(this.backgroundColor);
      }
      return classes.join(" ");
    },
    register() {
      return this.locale === "fr" ? "/jarrete/inscription" : "/iquitnow/registration";
    },
    login() {
      return this.locale === "fr" ? "/jarrete/connexion" : "/iquitnow/login";
    },
  },
};
</script>

<style scoped>
.button-container {
  height: 100px;
  background-image: url("/images/quit/illustration/home/img-arrete-illustration.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}

.button-container a {
  line-height: 2.625rem;
}
</style>

<i18n lang="json">
{
  "fr": {
    "register": "Je m'inscris à J'ARRÊTE",
    "login": "Je me connecte",
    "quit": "J'arrête"
  },
  "en": {
    "register": "Register to I QUIT NOW",
    "login": "Login",
    "quit": "I Quit"
  }
}
</i18n>
